import React, { Component } from "react";

// header
import AppHeader from "../../../components/AppHeader/AppHeader";

import { Image } from "cloudinary-react";
import axios from "axios";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import { cloneDeep } from "lodash";

// config
import config from "../../../config";

// email
import { sendEmailNotifecation } from "../../../services/SendEmail";

//constants
import constants from "../../../constants";

// utils
import LS from "../../../utils/LocalStorageService";

// css
import * as style from "./index.module.css";

class TwinStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      serverError: "",
      clientId: "",
      cgmOrderServicePurchased: "",
      planCode: "",
      planDetails: cloneDeep(constants.TWIN_STORE),
      appHeader: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { appHeader } = queryString.parse(props.location.search);
    if (appHeader) {
      LS.setValue("header", { appHeader: appHeader });
      return null;
    } else {
      LS.setValue("header", { appHeader: "mobile" });
      return null;
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const TWIN_STORE = cloneDeep(constants.TWIN_STORE);
    this.setState({
      cgmOrderServicePurchased: TWIN_STORE.plan2.cgmOrderServicePurchased,
      planCode: TWIN_STORE.plan2.planCode,
    });

    const { clientId, appHeader } = queryString.parse(
      this.props.location.search
    );

    appHeader && this.setState({ appHeader });

    if (clientId) {
      this.setState({ clientId: Number(clientId) });
    }
  }

  // capture CGM radio button values
  handleRadioButton(value1, value2) {
    if (value1 && value2) {
      this.setState({
        cgmOrderServicePurchased: value1,
        planCode: value2,
      });
    }
  }

  // sending request for payment
  handlePayNow = async () => {
    const paymentBody = {
      clientId: this.state.clientId,
      cgmOrderServicePurchased: this.state.cgmOrderServicePurchased,
      planCode: this.state.planCode,
    };
    // console.log("paymentBody", paymentBody);

    const paymentOptions = {
      method: "post",
      url: config.DX_API_BASE_URL + "/api/clients/storePayment",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${config.JWT_TOKEN}`,
      },
      data: paymentBody,
    };

    try {
      const response = await axios(paymentOptions);
      const paymentUrl = response.data.results[0].url;
      window.location.href = paymentUrl;
      this.setState({ loading: false });
    } catch (error) {
      console.error("payment api server error", error);
      this.setState({
        loading: false,
        serverError:
          "There seems to be an issue at our end. Please call +91 9005008946 to complete the payment",
      });
      // send an email
      sendEmailNotifecation(
        window.location.href,
        paymentBody,
        error.response.data || {},
        "/dx/cgmOrder/"
      );
    }
  };

  render() {
    const { cgmOrderServicePurchased, planDetails, serverError, appHeader } =
      this.state;

    return (
      <div className={`${style.app_container}`}>
        <Helmet>
          <title>Twin Store</title>
          <meta
            name="description"
            content="Exclusive Store For Existing Twin Members"
          />
        </Helmet>

        {appHeader !== "no" && (
          <AppHeader text="TWIN STORE" isBack={true} backUrl={"mobile_back"} />
        )}

        <div className="container-fluid">
          <div>
            <div className="d-flex justify-content-center align-items-center pb-3">
              <Image
                secure="true"
                cloudName={config.CLOUDINARY_NAME}
                publicId="app-images/store_tzg8hj"
                dpr="auto"
                responsive
                width="auto"
                crop="scale"
                responsiveUseBreakpoints="true"
                fetchFormat="webp"
                quality="auto"
              />
            </div>
            <div className={`${style.head1}`}>
              <h3>CGM Patch Service</h3>
              <p>Sensor(s) + TSA visits </p>
            </div>
            <div className={`${style.radio_container}`}>
              <div className="d-flex">
                <label className={`${style.cust_radio}`}>
                  <p>2 weeks CGM (1 sensor) </p>
                  <p>&#8377; {planDetails.plan1.offerPrice} </p>
                  <input
                    type="radio"
                    id="plan1"
                    name="plan"
                    checked={
                      cgmOrderServicePurchased ===
                      planDetails.plan1.cgmOrderServicePurchased
                    }
                    // onChange={() => this.handleRadioButton("TWO_WEEKS")}
                    onChange={() =>
                      this.handleRadioButton(
                        planDetails.plan1.cgmOrderServicePurchased,
                        planDetails.plan1.planCode
                      )
                    }
                  />
                  <span className={`${style.cust_radio_checkround}`}></span>
                </label>
              </div>
            </div>
            <div className={`${style.radio_container}`}>
              <p className={`${style.recommand}`}>RECOMMENDED</p>
              <div className="d-flex">
                <label className={`${style.cust_radio}`}>
                  <p> 4 weeks CGM (2 sensors) </p>
                  <p className={`${style.para2}`}>
                    &#8377; {planDetails.plan2.offerPrice}{" "}
                    <del className={`${style.del}`}>
                      &#8377;{planDetails.plan2.totalPrice}{" "}
                    </del>
                    <span className={`${style.off}`}>
                      {planDetails.plan2.offer} OFF
                    </span>{" "}
                  </p>
                  <input
                    type="radio"
                    id="plan2"
                    name="plan"
                    checked={
                      cgmOrderServicePurchased ===
                      planDetails.plan2.cgmOrderServicePurchased
                    }
                    onChange={() =>
                      this.handleRadioButton(
                        planDetails.plan2.cgmOrderServicePurchased,
                        planDetails.plan2.planCode
                      )
                    }
                  />
                  <span className={`${style.cust_radio_checkround}`}></span>
                </label>
              </div>
            </div>
            <div className={`${style.radio_container}`}>
              <div className="d-flex">
                <label className={`${style.cust_radio}`}>
                  <p> 8 weeks CGM (4 sensors) </p>
                  <p className={`${style.para2}`}>
                    &#8377; {planDetails.plan3.offerPrice}{" "}
                    <del className={`${style.del}`}>
                      &#8377;{planDetails.plan3.totalPrice}{" "}
                    </del>
                    <span className={`${style.off}`}>
                      {planDetails.plan2.offer} OFF
                    </span>{" "}
                  </p>
                  <input
                    type="radio"
                    id="plan3"
                    name="plan"
                    checked={
                      cgmOrderServicePurchased ===
                      planDetails.plan3.cgmOrderServicePurchased
                    }
                    // onChange={() => this.handleRadioButton("EIGHT_WEEKS")}
                    onChange={() =>
                      this.handleRadioButton(
                        planDetails.plan3.cgmOrderServicePurchased,
                        planDetails.plan3.planCode
                      )
                    }
                  />
                  <span className={`${style.cust_radio_checkround}`}></span>
                </label>
              </div>
            </div>
          </div>
          <div className="mt-3 mb-3">
            <p className={`${style.whtInc}`}>What's Included?</p>
            <ul className={`${style.list1}`}>
              <li>FreeStyle Libre Pro CGM Sensor(s)</li>
              <li>Sensor Activation</li>
              <li>Dedicated TSA Service twice a week</li>
            </ul>
            <p className={`${style.para1}`}>
              Sensor(s) will be delivered to your registered address within 4
              business days.
            </p>
            <p className={`${style.para1}`}>
              Standard Twin{" "}
              <a href="/terms" target="_blank">
                Terms of Service
              </a>{" "}
              Apply
            </p>
            <p className={`${style.para1}`}>
              <sup>*</sup>If you are a new member, your first 3 CGM patches is
              on us.{" "}
            </p>
            <button
              className={`${style.radio_btn}`}
              onClick={this.handlePayNow}
              type="button"
            >
              <Image
                secure="true"
                cloudName={config.CLOUDINARY_NAME}
                publicId="app-images/Shopping_cart_icon_2x_fkvf2w"
                dpr="auto"
                responsive
                crop="scale"
                responsiveUseBreakpoints="true"
                fetchFormat="webp"
                quality="auto"
                width="20"
              />{" "}
              <span> BUY NOW</span>{" "}
            </button>
            <div className="mt-2 text-center text-danger">
              {serverError} &nbsp;
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TwinStore;
